import React from "react";
import NumberFormat from "react-number-format";

function SummaryTableComponent(props) {
  const results = props.results;
  const decimals = props.decimals ?? 2;
  if (!results) return <></>;
  console.log("Results:" , results);
  return (
    <>
      <h5>{props.title}</h5>
      <table className="table table-bordered summaryTable">
        <thead>
          <tr>
            {results.map((result) => {
              return <th key={result.name} colSpan={result.outputs.length}>{result.name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {results.map((result) => {
                return result.outputs.map((output) => {
                  return (
                    <td key={output.value}>
                      &nbsp;
                      <NumberFormat
                        displayType="text"
                        thousandSeparator={true}
                        value={output.value}
                        decimalScale={decimals}
                        prefix={output.p}
                        suffix={output.s}
                      />
                    </td>
                  );
                });
            })}
          </tr>
        </tbody>
      </table>
    </>
  );
}
export default SummaryTableComponent;
