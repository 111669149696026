import { calcAmortPrincipal, annualizedReturn } from "../shared/financialFunctions";

export const calculateExampleOneResults = (simulationState,years = -1) => {
  const savingsToday = simulationState["savingsToday"];
  const saveYears = simulationState["saveYears"];
  const pensionYears = simulationState["pensionYears"];
  const currentAnualIncome = simulationState["currentAnualIncome"];
  const annualPension = simulationState["annualPension"];
  const annualReturnRate = simulationState["annualReturnRate"];
  const annualInflation = simulationState["annualInflation"] ?? 0;
  const endingAnnualSalary = simulationState["endingAnnualSalary"] ?? 0;

  if(years===-1)
    years = pensionYears;

  const annualGrowthRate = annualizedReturn( endingAnnualSalary, currentAnualIncome, saveYears);
  const totalInflationRate = (1+annualInflation/100) ** (saveYears-1); 
  const neededAmount = calcAmortPrincipal(annualPension, 1, annualReturnRate, years) * totalInflationRate;
  const annualSavings = calculateAnnuity(savingsToday, saveYears, annualReturnRate, annualGrowthRate, neededAmount);

  
  let results = [];
  results.push(addResult("Fund Size", [neededAmount]));
  results.push(addResult("Annual Savings", [annualSavings, percentageString(annualSavings, currentAnualIncome)]));
  endingAnnualSalary && results.push(addResult("Income Growth", [ String(100*annualGrowthRate) + "%"]));
  return results;
};

const addResult = (name, values) => {
  let outputs = [];
  values.forEach((x) => {
    outputs.push({ value: x, p: (x && x.includes && x.includes("%"))?"":"$" , s: (x && x.includes && x.includes("%"))?"%":"" });
  });
  let result = {
    name: name,
    outputs: outputs,
  };
  return result;
};

const calculateAnnuity = (savingsToday, saveYears, annualReturnRate, resultingAnnualGrowthRate, neededAmount) => {
  const tmp1 = Math.pow(1 + annualReturnRate / 100, saveYears);
  const tmp2 = Math.pow(1 + resultingAnnualGrowthRate, saveYears);
  const tmp3 = annualReturnRate / 100 - resultingAnnualGrowthRate;
  const tmp4 = (tmp1 - tmp2) / tmp3;
  const tmp5 = savingsToday * tmp1;
  const neededYearlySavePerpetualGrowth =  (neededAmount - tmp5) / tmp4;
  return neededYearlySavePerpetualGrowth;
};

const percentageString = (partial, total) => {
  return (((100 * partial) / total).toFixed(2) + "%").toString();
};
