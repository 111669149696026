import React, { useState } from "react";
import Example from "./Example";
import ToggleSwitch from "../components/utility/ToggleComponent";

import "./ExampleOneComponent.css";

function ExampleOne() {

  const [reducedLineHeight, setReducedLineHeight] = useState(true);


  return (
    <>
      <Example id="ExampleOne" name="Example One" reducedLineHeight={reducedLineHeight}></Example>
      <Example id="ExampleTwo" name="Example Two" reducedLineHeight={reducedLineHeight}></Example>
      <Example id="ExampleThree" name="Example Three" reducedLineHeight={reducedLineHeight}></Example>
      <Example id="ExampleFour" name="Example Four" reducedLineHeight={reducedLineHeight}></Example>
      <Example id="ExampleFive" name="Example Five" reducedLineHeight={reducedLineHeight}></Example>
      <Example id="ExampleSix" name="Example Six" reducedLineHeight={reducedLineHeight}></Example>
      <div className="bottomRight">
        
        <ToggleSwitch title={reducedLineHeight ? "Reduced line height" : "Reduce line height"} value={reducedLineHeight} setter={setReducedLineHeight}></ToggleSwitch>
      </div>
    </>
  );
}
export default ExampleOne;
