import React, { useState, useEffect } from "react";
import Customizable from "../components/CustomizableComponent";
import SimulationParameterComponent from "../components/SimulationParameterComponent";
import { calculateExampleOneResults } from "../logic/simulationLogic";
import { selectParameters } from "../shared/simulationParameters";
import SummaryTableComponent from "../components/SummaryTableComponent";
import "./ExampleOneComponent.css";
import { Button } from "reactstrap";

function Example(props) {

  const includeInflation = true;
  const includeIncomeGrowth = true;
  
  const selectedParameters = selectParameters([
    "savingsToday",
    "saveYears",
    "pensionYears",
    "currentAnualIncome",
    "annualPension",
    "annualReturnRate",
    ...(includeInflation ? ["annualInflation"] : []),
    ...(includeIncomeGrowth ? ["endingAnnualSalary"] : [])
  ]);
  
  const [state, setState] = useState({
    savingsToday: 50000,
    saveYears: 30,
    pensionYears: 30,
    currentAnualIncome: 50000,
    annualPension: 50000,
    annualReturnRate: 4
  });
  const [userState, setUserState] = useState(null);
  const [userId, setUserId] = useState("Default");
  const [results, setResults] = useState(null);
  const [resultMaintain, setResultsMaintain] = useState(null);
  const [viewDecimals] = useState(true);
  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const saveUserData = (_userId) => {
    setUserState({
      UserId: _userId, UserConfigurations: Object.keys(state).map((n) => {
        return {
          Name: n,
          Value: state[n].toString()
        }
      })
    });
  }
  useEffect(() => {
    setResults(calculateExampleOneResults(state));
    setResultsMaintain(calculateExampleOneResults(state,1000));
  }, [state]);

  useEffect(() => {
    fetch("/UserConfiguration?UserId=" + userId, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Gotten data", data);
        setState(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId]);

  useEffect(() => {
    if (userState == null)
      return;
    fetch("/UserConfiguration", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userState),
    })
      .then((response) => {
        console.log("Saved user state!", response);
        setUserId(userState.UserId);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userState]);

  return (
    <div id={props.id + "div"} style={{ marginBottom: "2rem" }} className={"card " + (props.reducedLineHeight ? "reducedLineHeight" : "")}>
      <div className="RowContent" style={{ margin: "1rem" }}>
        <div className="col-sm-12">
          <h4>{props.name} </h4>
          <Customizable name={props.id + "Explanation"}></Customizable>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-8 col-sm-12">

          <h4>Inputs</h4>
          {selectedParameters.map((param) => {
            return (
              <SimulationParameterComponent
                key={param.name}
                param={param}
                viewDecimals={viewDecimals}
                setParam={onChange}
                value={state[param.name]}
              ></SimulationParameterComponent>
            );
          })}
          <Button onClick={() => saveUserData(window.prompt("Enter your email:"))}>Save</Button>
          <Button onClick={() => setUserId(window.prompt("Enter your email:"))}>Load</Button>

        </div>
        <div className="col-md-4 col-sm-12">
          <h4>Results</h4>
          <SummaryTableComponent title="Fund will be exhausted" results={results}></SummaryTableComponent>
          <SummaryTableComponent title="Fund will be maintained" results={resultMaintain}></SummaryTableComponent>
        </div>
      </div>


    </div>
  );
}
export default Example;
