const MAX_VAL = 99;
const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

export const simulationParameters = [
	{
		name: "savingsToday", label: "Saved Amount",
		description: "Amount of net worth already accumulated to be used in retirement (U.S. dollars)", t: true, p: "$"
	},
	{
		name: "saveYears", label: "Save Years",
		description: "For how many years do you plan to save for your retirement?", s: " years", rule: withValueLimit
	},
	{
		name: "pensionYears", label: "Pension Years",
		description: "For how many years do you expect you will live after retiring?", s: " years", rule: withValueLimit
	},
	{
		name: "currentAnualIncome", label: "Current Annual Income",
		description: "What is your current annual income? (U.S. dollars)", t: true, p: "$",
		tip: "Or if you are not working, what is your expected annual income once you start saving (in today's dollars)"
	},
	{
		name: "annualPension", label: "Desired Annual Pension",
		description: "What is your desired annual pension? (U.S. dollars)", t: true, p: "$", tip: "In today's dollars"
	},
	{
		name: "annualReturnRate", label: "Annual Return Rate",
		description: "What annual real return do you expect to earn in your retirement fund? ", s: "%", f: "2", tip: "4.00% Recommended, 3.00% if you are very conservative."
	},
	{
		name: "endingAnnualSalary", label: "Ending Annual Income",
		description: "Expected ending annual income just before retiring (in today's dollars)",
		t: true, p: "$", stage: "constantPercentage",
		tip: "You may enter your own estimate"
	},
	{
		name: "annualInflation", label: "Inflation",
		description: "Type here your estimate for the annual inflation rate",
		s: "%", f: "2",
		tip: `The annual inflation rate is usually 2.5%.`, fillColor: "#f1c40f"
	},
	{
		name: "neededAmount", label: "Fund Size",
		description: "This is how much should you save in order to generate the desired pension. (In today's dollars)",
		rol: "output", t: true, p: "$",
		tip: "Total amount needed at the end of the savings period"
	},
	{
		name: "neededYearlySave", label: "Annual Savings",
		description: "Amount to be saved annually.", rol: "output", t: true, p: "$",
		tip: "Amount to be saved annually. Amount in dollars may change because of inflation or income growth. Amount in percentage will remain constant."
	},
	{
		name: "percetageYearlySave", label: "(%)",
		description: "This is the % of your income you need to save.", rol: "output", s: "%"
	},
	{
		name: "resultingAnnualGrowthRate", label: "Inc. Growth",
		description: "This is the resulting annual real growth rate of your income (it is usually between 3% and 4%)",
		s: "%", stage: "constantPercentage", rol: "output", f: "2",
		tip: `Annual Income Growth. Represents how much your annual income increases every year. 
		This is the result of taking into account your current and expected ending annual income. If you want to assume income growth, use the advanced option 'Incorporate Income Growth' above.`,
		fillColor: "#81ecec"
	},
];

export const selectParameters = (params) =>{
	return  simulationParameters.filter(X=>params.includes(X.name));
}
