import React, { Component } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { Form } from "react-redux-form";
import {
  calcAmortPrincipal,
  annuity,
  annualizedReturn,
} from "../shared/financialFunctions";
import './RetirementSimulatorComponent.css'
import NumberFormat from "react-number-format";
import Switch from "react-switch";
import ReactToPrint from "react-to-print";
import ReactTooltip from "react-tooltip";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import ResultsTableBody from "./ResultTableComponent";
import { simulationParameters } from "../shared/simulationParameters";
import SimulationParameterComponent from "./SimulationParameterComponent";
import ToggleSwitch from "../components/utility/ToggleComponent";

class RetirementSimulator extends Component {
  constructor(props) {
    super(props);
    this.calculateValues = this.calculateValues.bind(this);
    this.calculateConstantSavings = this.calculateConstantSavings.bind(this);
    this.toggleProp = this.toggleProp.bind(this);

    this.formatMoneyAxis = this.formatMoneyAxis.bind(this);
    this.animateTransition = this.animateTransition.bind(this);
    this.validForm = this.validForm.bind(this);
    this.fillValues = this.fillValues.bind(this);
    this.clearValues = this.clearValues.bind(this);
    this.clearInflation = this.clearInflation.bind(this);
    this.touchValue = this.touchValue.bind(this);
    this.setReducedLineHeight = this.setReducedLineHeight.bind(this);
    this.submitCalculateForm = React.createRef();
    this.setParam = this.setParam.bind(this);
    this.state = {
      isTableOpen: false,
      isPerpetual: false,
      incomeGrowthOrder: false,
      constantValue: true,
      viewDecimals: false,
      showAdvanced: true,
      showNominal: false,
      readMoreDescription: false,
      includeInflation: false,
      showLess: true,
      reducedLineHeight: true,
      counter: 0,
      firstRun: true,
    };
  }
  setReducedLineHeight(value) {
    this.setState({
      ...this.state,
      reducedLineHeight: value
    });
  }
  //Saving methods
  calculateConstantSavings() {
    return this.state.neededYearlySave;
  }
  formatMoneyAxis(tickItem) {
    return "$" + tickItem;
  }
  toggleProp(name, dataReady = -1) {
    if (dataReady === -1) dataReady = this.state.dataReady;

    let propVal = !this.state[name];
    this.setState({
      ...this.state,
      [name]: propVal,
      dataReady: dataReady,
    });
  }
  validForm() {
    let v = this.state;
    if (
      !isNaN(v.savingsToday) &&
      v.saveYears > 0 &&
      v.pensionYears > 0 &&
      v.currentAnualIncome > 0 &&
      v.annualPension > 0 &&
      v.annualReturnRate > 0 &&
      (this.state.constantValue || this.state.endingAnnualSalary > 0)
    )
      return true;
    return false;
  }

  calculateValues(values) {
    let v = this.state;
    const adjustedPension = v.annualPension;
    const neededAmount = calcAmortPrincipal(
      adjustedPension,
      1,
      v.annualReturnRate,
      v.pensionYears
    );
    const neededAmountPerpetual = calcAmortPrincipal(
      adjustedPension,
      1,
      v.annualReturnRate,
      100000
    );
    let neededYearlySave = 0;
    let neededYearlySavePerpetual = 0;
    let resultingAnnualGrowthRate = 0;

    resultingAnnualGrowthRate = annualizedReturn(
      v.endingAnnualSalary,
      v.currentAnualIncome,
      v.saveYears
    );
    const tmp1 = Math.pow(1 + v.annualReturnRate / 100, v.saveYears);
    const tmp2 = Math.pow(1 + resultingAnnualGrowthRate, v.saveYears);
    const tmp3 = v.annualReturnRate / 100 - resultingAnnualGrowthRate;
    const tmp4 = (tmp1 - tmp2) / tmp3;
    const tmp5 = v.savingsToday * tmp1;
    neededYearlySave = annuity(
      neededAmount - tmp5,
      1,
      v.annualReturnRate,
      v.saveYears
    );
    neededYearlySavePerpetual = annuity(
      neededAmountPerpetual - tmp5,
      1,
      v.annualReturnRate,
      v.saveYears
    );
    const percetageYearlySave = (neededYearlySave / v.currentAnualIncome) * 100;
    const percetageYearlySavePerpetual =
      (neededYearlySavePerpetual / v.currentAnualIncome) * 100;
    const neededYearlySaveGrowth = (neededAmount - tmp5) / tmp4;
    const neededYearlySavePerpetualGrowth =
      (neededAmountPerpetual - tmp5) / tmp4;
    resultingAnnualGrowthRate *= 100;
    const percetageYearlySaveGrowth =
      (neededYearlySaveGrowth / v.currentAnualIncome) * 100;
    const percetageYearlySavePerpetualGrowth =
      (neededYearlySavePerpetualGrowth / v.currentAnualIncome) * 100;

    this.setState(
      {
        ...this.state,
        neededAmount: neededAmount,
        neededAmountPerpetual: neededAmountPerpetual,
        neededYearlySave: neededYearlySave,
        percetageYearlySave: percetageYearlySave,
        neededYearlySavePerpetual: neededYearlySavePerpetual,
        percetageYearlySavePerpetual: percetageYearlySavePerpetual,
        adjustedReturnRate:
          parseFloat(v.annualReturnRate) + parseFloat(v.annualInflation),
        resultingAnnualGrowthRate: 0,
        resultingAnnualGrowthRatePerpetual: 0,
        resultingAnnualGrowthRateGrowth: resultingAnnualGrowthRate,
        resultingAnnualGrowthRatePerpetualGrowth: resultingAnnualGrowthRate,
        neededYearlySaveGrowth: neededYearlySaveGrowth,
        neededYearlySavePerpetualGrowth: neededYearlySavePerpetualGrowth,
        percetageYearlySaveGrowth: percetageYearlySaveGrowth,
        percetageYearlySavePerpetualGrowth: percetageYearlySavePerpetualGrowth,
        dataReady: neededYearlySave !== 0,
        updateResultsTableBody: true,
        counter: this.state.counter + 1,
        graphicData: this.state.combinedData,
        firstRun: false,
      },
      () => {
        let stringState = JSON.stringify(this.state);
        window.sessionStorage.setItem("main", stringState);
        this.runSimulation("", () => {
          this.runSimulation("Growth");
        });
      }
    );

    window.sessionStorage.setItem("testtest", "testtest");
    window.sessionStorage.setItem("test2", JSON.stringify(this.state));
    ReactTooltip.rebuild();
  }
  runSimulation(type = "", callback = null) {
    console.log("Running sim...");
    let per = 0;
    let bb = this.state.savingsToday;
    let bbP = this.state.savingsToday;
    let returnBB = 0;
    let returnBBP = 0;
    let savings = 0;
    let savingsP = 0;
    let pension = this.state.annualPension;
    let eb = bb;
    let ebP = bb;
    let conditions = ["SAVING", "RETIRED"];
    let pensionYears = this.state.pensionYears;
    let yearlySave = this.state["neededYearlySave" + type];
    let yearlySavePerpetual = this.state["neededYearlySavePerpetual" + type];
    let grossSavings = this.state.savingsToday;
    let grossSavingsP = this.state.savingsToday;
    let dataFungible = [];
    let dataPermanent = [];
    let detailedDataFungible = [];
    let detailedDataPermanent = [];
    let tmpFun = this.state.detailedDataFungible;
    let tmpPer = this.state.detailedDataPermanent;
    let neededReal = 0;
    let neededPerpetualReal = 0;
    let resultingAnnualGrowthRate =
      this.state["resultingAnnualGrowthRate" + type];
    // let changed = false;
    let inflation = 1;
    let realIncrease = 1;
    for (let condition of conditions) {
      const end = condition === "SAVING" ? this.state.saveYears : pensionYears;
      if (condition !== "SAVING") {
        neededReal = eb;
        neededPerpetualReal = ebP;
      }
      for (let i = 0; i < end; i++) {
        per++;
        bb = eb;
        bbP = ebP;
        let temp7 =
          (1 + this.state.annualReturnRate / 100) *
          (1 + this.state.annualInflation / 100) -
          1;
        if (i === 0 && condition === "SAVING")
          temp7 = (1 + this.state.annualReturnRate / 100) * 1 - 1;
        returnBB = Number(bb * temp7);
        returnBBP = Number(bbP * temp7);
        savings = Number(condition === "SAVING" ? yearlySave : 0);
        savingsP = Number(condition === "SAVING" ? yearlySavePerpetual : 0);
        pension = Number(
          condition === "RETIRED" ? this.state.annualPension * inflation : 0
        );
        eb = +bb + savings + returnBB - pension;
        ebP = +bbP + savingsP + returnBBP - pension;
        grossSavings += savings;
        grossSavingsP += savingsP;
        // if (tmpFun && tmpFun.length > i && tmpFun[i].savings !== savings) changed = true;
        dataFungible.push({
          per: per,
          name: condition,
          Fund_Size: Math.round(eb),
          nominal: Math.round(grossSavings),
        });
        dataPermanent.push({
          per: per,
          name: condition,
          Fund_Size: Math.round(ebP),
          nominal: Math.round(grossSavingsP),
        });
        detailedDataFungible.push({
          savings: savings,
          pension: pension,
          per: per,
          bb: bb,
          eb: eb,
          returnBB: returnBB,
          condition: condition,
          realIncrease: realIncrease,
          inflation: this.state.annualInflation,
          annualGrowthRate: resultingAnnualGrowthRate
        });
        detailedDataPermanent.push({
          savings: savingsP,
          pension: pension,
          per: per,
          bb: bbP,
          eb: ebP,
          returnBB: returnBBP,
          condition: condition,
          realIncrease: realIncrease,
          inflation: this.state.annualInflation,
          annualGrowthRate: resultingAnnualGrowthRate
        });
        realIncrease =
          (1 + resultingAnnualGrowthRate / 100) *
          (1 + this.state.annualInflation / 100);
        yearlySave =
          yearlySave *
          (1 + resultingAnnualGrowthRate / 100) *
          (1 + this.state.annualInflation / 100);
        yearlySavePerpetual =
          yearlySavePerpetual *
          (1 + resultingAnnualGrowthRate / 100) *
          (1 + this.state.annualInflation / 100);
        inflation *= 1 + this.state.annualInflation / 100;
      }
    }

    this.setState(
      {
        ...this.state,
        combinedData: dataFungible,
        neededAmount: neededReal,
        neededAmountPerpetual: neededPerpetualReal,
        ["combinedData" + type]: dataPermanent,
        ["prevDetailedFungible" + type]: tmpFun,
        ["prevDetailedPermanent" + type]: tmpPer,
        ["detailedDataFungible" + type]: detailedDataFungible,
        ["detailedDataPermanent" + type]: detailedDataPermanent,
      },
      () => {
        this.animateTransition();
        if (callback) {
          callback();
        }
      }
    );
  }
  fillValues() {
    this.setState({
      ...this.state,
      savingsToday: 10000,
      saveYears: 40,
      pensionYears: 30,
      currentAnualIncome: 40000,
      annualPension: 36000,
      annualReturnRate: 4.0,
      adjustedReturnRate: 4.0,
      endingAnnualSalary: 80000,
      annualInflation: 0,
    });
  }
  clearValues() {
    this.setState({
      ...this.state,
      savingsToday: "",
      saveYears: "",
      pensionYears: "",
      currentAnualIncome: "",
      annualPension: "",
      annualReturnRate: "",
      adjustedReturnRate: "",
      endingAnnualSalary: "",
      annualInflation: "",
      constantValue: true,
      dataReady: false,
    });
  }
  clearInflation() {
    const infValue = this.state.includeInflation;
    this.setState({
      ...this.state,
      annualInflation: infValue ? 2.5 : 0,
    });
  }
  componentDidMount() {


    let stringState = window.sessionStorage.getItem("main"); 

    if (stringState)
      this.setState(JSON.parse(stringState));
    else
      this.fillValues();

  }
  animateTransition() {
    let elems = document.querySelectorAll(".animate");
    console.log("To animate " + elems.length.toString());

    [].forEach.call(elems, function (el) {
      el.animate(
        [
          // keyframes
          {
            backgroundColor: "#3498db",
          },
          {
            backgroundColor: "#ffffff",
          },
        ],
        {
          duration: 1500,
          iterations: 1,
        }
      );
    });
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  touchValue(name) {
    /*
    if (!this.state[name + "touched"])
      this.setState({
        ...this.state,
        [name]: "",
        [name + "touched"]: true,
      });
    */
  }
  setParam(name, value) {
    this.setState(
      {
        ...this.state,
        [name]: value,
      },
      () => {
        this.calculateValues();
      }
    );
  }

  render() {
    const parameters = simulationParameters;
    const perpetuityValues = [false, true];
    const withIncomeGrowthValues = [
      this.state.incomeGrowthOrder,
      !this.state.incomeGrowthOrder,
    ];
    return (
      <div>
        <Form
          model="simulationdata"
          onSubmit={(values) => this.calculateValues(values)}
        >
          <div
            className="container"
            style={{ maxWidth: "3000px" }}
            ref={(el) => (this.componentRef = el)}
          >
            <div className="row-content">
              <Row className="card-simulation-style-padding">
                <div
                  className="container-fluid card-simulation-style"
                  id="mainParametersDiv"
                >
                  <Row>
                    <Col md={9} sm={12} style={{ fontSize: "1rem" }}>
                      <h4>Simulation Parameters</h4>
                      <h6>Use your own estimates or default values</h6>
                      {parameters.map((param) => {
                        return param.name === "endingAnnualSalary" ||
                          param.name === "annualInflation" ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <SimulationParameterComponent
                            param={param}
                            viewDecimals={this.state.viewDecimals}
                            touchValue={this.touchValue}
                            setParam={this.setParam}
                            value={this.state[param.name]}
                          ></SimulationParameterComponent>
                        );
                      })}

                      <div
                        style={{
                          opacity: !this.state.constantValue ? "1" : "0",
                        }}
                      >
                        <SimulationParameterComponent
                          param={parameters.find(
                            (x) => x.name === "endingAnnualSalary"
                          )}
                          viewDecimals={this.state.viewDecimals}
                          touchValue={this.touchValue}
                          setParam={this.setParam}
                          value={
                            this.state[
                            parameters.find(
                              (x) => x.name === "endingAnnualSalary"
                            ).name
                            ]
                          }
                        ></SimulationParameterComponent>
                        <div class="form-group fadeIn row">
                          <label class="simulationParameterLabel col-md-9 col-form-label">
                            Resulting annual growth rate&nbsp;
                            <i
                              data-tip="Usually between 2% and 3%"
                              class="fa-info-circle-alt"
                              currentitem="false"
                            >
                              i
                            </i>
                          </label>

                          <div class="col-md-3">
                            <input
                              model=".endingAnnualSalary"
                              id="endingAnnualSalary"
                              name="endingAnnualSalary"
                              autocomplete="off"
                              placeholder="Ending Annual Income"
                              class="form-control"
                              type="text"
                              value={
                                (
                                  this.state.resultingAnnualGrowthRateGrowth ??
                                  0
                                ).toFixed(2) + "%"
                              }
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          opacity: this.state.includeInflation ? "1" : "0",
                        }}
                      >
                        <SimulationParameterComponent
                          param={parameters.find(
                            (x) => x.name === "annualInflation"
                          )}
                          viewDecimals={this.state.viewDecimals}
                          touchValue={this.touchValue}
                          setParam={this.setParam}
                          value={
                            this.state[
                            parameters.find(
                              (x) => x.name === "annualInflation"
                            ).name
                            ]
                          }
                        ></SimulationParameterComponent>
                        <div
                          class="form-group fadeIn row"
                          style={{ display: "none" }}
                        >
                          <label class="simulationParameterLabel col-md-9 col-form-label">
                            Adjusted to inflation annual rate&nbsp;
                            <i
                              data-tip="Annual return rated adjusted for inflation"
                              class="fa-info-circle-alt"
                              currentitem="false"
                            >
                              i
                            </i>
                          </label>
                          <div class="col-md-3">
                            <input
                              autocomplete="off"
                              placeholder="Adjusted annual rate"
                              class="form-control"
                              type="text"
                              value={
                                (
                                  parseFloat(this.state.annualReturnRate) +
                                  parseFloat(this.state.annualInflation) ?? 0
                                ).toFixed(2) + "%"
                              }
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col
                      className="form-group btn-row"
                      md={3}
                      sm={12}
                      style={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        marginLeft: "-1rem",
                      }}
                    >
                      <Button
                        type="button"
                        onClick={() => {
                          var element = document.getElementById("ResultsTitle");

                          element.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "nearest",
                          });
                        }}
                        color="primary"
                        data-tip="See results"
                        hidden={!this.state.dataReady}
                      >
                        Go to results
                      </Button>
                      <Button
                        type="button"
                        onClick={this.clearValues}
                        data-tip="Clear estimates and results"
                        color="warning"
                        className="btn-custom-warning"
                      >
                        Clear
                      </Button>
                      <Button
                        type="button"
                        onClick={this.fillValues}
                        data-tip="Default values will be reinstated"
                        color="danger"
                      >
                        Reset
                      </Button>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <Button
                              data-tip="Print/Download"
                              color="success"
                              type="button"
                            >
                              Print
                            </Button>
                          );
                        }}
                        content={() => this.componentRef}
                      />

                      <div hidden={!this.state.dataReady}>
                        <h5>Advanced options:</h5>
                        <div>
                          <Switch
                            height={15}
                            width={30}
                            onColor={"#81ecec"}
                            offColor={"#81ecec"}
                            onChange={() => this.toggleProp("constantValue")}
                            checked={!this.state.constantValue}
                          />
                          <span>
                            &nbsp; Incorporate Income Growth&nbsp;
                            <i
                              data-tip={`Incorporating income growth will result in lower initial savings because your savings will grow at the same rate of your income.\n
                              This option requires including your expected annual income just before retirement. The income growth rate will be calculated with this information. Change the default value at your convenience.`}
                              className="fa-info-circle-alt"
                            >
                              i
                            </i>
                          </span>
                        </div>
                        <div>
                          <Switch
                            height={15}
                            width={30}
                            onColor={"#f1c40f"}
                            offColor={"#f1c40f"}
                            onChange={() => {
                              this.toggleProp("includeInflation");
                              this.clearInflation();
                            }}
                            checked={this.state.includeInflation}
                          />
                          <span>
                            &nbsp; Incorporate Inflation&nbsp;
                            <i
                              data-tip={
                                "Incorporating inflation only changes values from the second year on. It only changes dollar values from the second year on."
                              }
                              className="fa-info-circle-alt"
                            >
                              i
                            </i>
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={9} style={{ fontSize: "1rem" }}>
                      <Col md={3}></Col>
                    </Col>
                  </Row>
                  <br />
                </div>
                <button
                  type="submit"
                  ref={this.submitCalculateForm}
                  hidden={true}
                />
              </Row>

              <br />
              <Col id="mainResultsTable" md={12}></Col>
              <Col md={6} hidden={!this.state.dataReady}></Col>
              <Col
                md={12}
                hidden={!this.state.dataReady}
                className="mline"
                style={{ padding: "0" }}
              >
                <h3
                  id="ResultsTitle"
                  hidden={!this.state.dataReady}
                  style={{ textAlign: "center" }}
                >
                  Simulation Results&nbsp;
                  {!this.state.constantValue && (
                    <Button
                      className="btn-sm"
                      data-tip="Swap results from with income growth/without income growth"
                      style={{ padding: "0 0.5rem" }}
                      type="button"
                      color="primary"
                      onClick={() => this.toggleProp("incomeGrowthOrder")}
                    >
                      <span className="fa fa-exchange fa-rotate-90"></span>
                    </Button>
                  )}
                </h3>
                <Row
                  className="row_media_fix"
                  style={{
                    display: "flex",
                    width: "100%",
                  }}

                >
                  {perpetuityValues.map((perpetual) => {
                    let resultParams = parameters.filter(
                      (x) => x.rol === "output"
                    );

                    return (
                      <Col
                        className="table"
                        key={"colo" + perpetual}
                        style={{
                          margin: "auto",
                          justifyContent: "center",
                        }}
                        lg={{ size: 6 }}
                        md={{ size: 12 }}
                      >
                        {perpetual ? (
                          <h4 style={{ textAlign: "center" }}>
                            Fund remains constant&nbsp;
                            <i
                              data-tip={
                                "After a number of withdrawal years the fund remains constant."
                              }
                              className="fa-info-circle-alt"
                            >
                              i
                            </i>
                          </h4>
                        ) : (
                          <h4 style={{ textAlign: "center" }}>
                            Fund will be exhausted&nbsp;
                            <i
                              data-tip={
                                "After a number of withdrawal years the fund will be exhausted."
                              }
                              className="fa-info-circle-alt"
                            >
                              i
                            </i>
                          </h4>
                        )}
                        <table
                          className="table"
                          style={{
                            fontSize: "0.8rem",
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                {!this.state.constantValue
                                  ? "Includes income growth?"
                                  : ""}
                              </th>
                              {resultParams.map((outputParam) => {
                                if (outputParam.name !== "percetageYearlySave")
                                  return (
                                    <th
                                      key={"outparam" + outputParam.label}
                                      style={{
                                        textAlign: "center",
                                        fontWeight: 500,
                                      }}
                                      colSpan={
                                        outputParam.name === "neededYearlySave"
                                          ? 2
                                          : 1
                                      }
                                    >
                                      {outputParam.label}
                                      &nbsp;
                                      {outputParam.tip ? (
                                        <i
                                          data-tip={outputParam.tip}
                                          className="fa fa-xs fa-info-circle-alt"
                                          style={{
                                            color:
                                              outputParam.fillColor ??
                                              "#000000",
                                          }}
                                        >
                                          i
                                        </i>
                                      ) : (
                                        <></>
                                      )}
                                    </th>
                                  );

                                return <React.Fragment></React.Fragment>;
                              })}
                            </tr>
                            {withIncomeGrowthValues.map((withIncomeGrowth) => {
                              if (
                                this.state.constantValue &&
                                withIncomeGrowth
                              ) {
                                return <></>;
                              }
                              return (
                                <tr>
                                  <td>
                                    <span
                                      style={{
                                        opacity: !this.state.constantValue
                                          ? "1"
                                          : "0",
                                      }}
                                    >
                                      {withIncomeGrowth
                                        ? "With income growth "
                                        : (!this.state.constantValue ? "Without income growth ":"")}
                                      <i
                                        data-tip={
                                          withIncomeGrowth
                                            ? "Annual Income grows over time"
                                            : "Annual Income stays the same over time."
                                        }
                                        className="fa-info-circle-alt"
                                      >
                                        {"i"}
                                        &nbsp;
                                      </i>
                                    </span>
                                  </td>

                                  {resultParams.map((outputParam) => {
                                    let decimals = outputParam.f
                                      ? outputParam.f
                                      : this.state.viewDecimals
                                        ? 2
                                        : 0;
                                    let outputAdd = perpetual
                                      ? "Perpetual"
                                      : "";
                                    outputAdd +=
                                      withIncomeGrowth &&
                                        this.state[
                                        outputParam.name + outputAdd + "Growth"
                                        ]
                                        ? "Growth"
                                        : "";
                                    if (
                                      outputParam.name !== "percetageYearlySave"
                                    )
                                      return (
                                        <>
                                          <td
                                            style={{
                                              textAlign: "center",
                                            }}
                                            className="animate"
                                            key={"outputtd" + outputParam.name}
                                          >
                                            <NumberFormat
                                              displayType="text"
                                              thousandSeparator={true}
                                              value={parseFloat(
                                                this.state[
                                                outputParam.name + outputAdd
                                                ]
                                              ).toFixed(2)}
                                              decimalScale={decimals}
                                              prefix={outputParam.p}
                                              suffix={outputParam.s}
                                            />
                                          </td>
                                          {outputParam.name ===
                                            "neededYearlySave" ? (
                                            <td
                                              style={{
                                                textAlign: "center",
                                              }}
                                              className="animate"
                                            >
                                              <span
                                                style={{
                                                  marginLeft: "0.5rem",
                                                }}
                                              >
                                                <NumberFormat
                                                  displayType="text"
                                                  thousandSeparator={true}
                                                  value={
                                                    this.state[
                                                    "percetageYearlySave" +
                                                    outputAdd
                                                    ]
                                                  }
                                                  decimalScale={2}
                                                  suffix={"%"}
                                                />
                                                &nbsp;
                                                <i
                                                  data-tip={
                                                    "As a percentage of annual income"
                                                  }
                                                  className="fa-info-circle-alt"
                                                >
                                                  i
                                                </i>
                                              </span>
                                            </td>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      );
                                    return <React.Fragment></React.Fragment>;
                                  })}
                                </tr>
                              );
                            })}
                          </thead>
                        </table>
                        <br />
                      </Col>
                    );
                  })}
                </Row>


              </Col>
              <div class="printableDiv" ref={(el) => (this.tableRef = el)}>

                {(this.state.constantValue
                  ? [""]
                  : [
                    this.state.incomeGrowthOrder ? "Growth" : "",
                    this.state.incomeGrowthOrder ? "" : "Growth",
                  ]
                ).map((type) => {
                  return (
                    <>
                      <Row
                        hidden={!this.state.dataReady}
                        className="mline"
                        style={{ marginTop: "1rem" }}
                      >
                        {perpetuityValues.map((param) => {
                          return (
                            <Col key={"colo" + param} lg={{ size: 6 }} md={{ size: 12 }}>
                              <h5 style={{ textAlign: "center" }}>
                                {type
                                  ? "With income growth"
                                  : (!this.state.constantValue ? "Without income growth ":"")}
                                &nbsp;
                                <i
                                  data-tip={
                                    param
                                      ? "After a number of withdrawal years the fund remains constant."
                                      : "After a number of withdrawal years the fund will be exhausted."
                                  }
                                  className="fa-info-circle-alt"
                                >
                                  i
                                </i>
                              </h5>

                              <Table
                                striped
                                bordered
                                hover
                                className="td_style center_content"
                               
                              >
                                <thead>
                                  <tr
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <td style={{ width: "10%" }}>YEAR</td>
                                    <td>BEG. BAL.</td>
                                    <td>RETURN</td>
                                    <td>SAVINGS</td>
                                    <td>WITHDRAWALS</td>
                                    <td>END. BAL.</td>
                                    <td>STATE</td>
                                  </tr>
                                </thead>
                                <ResultsTableBody
                                  saveYears={this.state.saveYears}
                                  pensionYears={this.state.pensionYears}
                                  isPerpetual={param}
                                  data={
                                    param
                                      ? this.state["detailedDataPermanent" + type]
                                      : this.state["detailedDataFungible" + type]
                                  }
                                  prevData={
                                    param
                                      ? this.state["prevDetailedPermanent" + type]
                                      : this.state["prevDetailedFungible" + type]
                                  }
                                  decimalScale={this.state.viewDecimals ? 2 : 0}
                                  showLess={this.state.showLess}
                                  counter={this.counter}
                                ></ResultsTableBody>
                              </Table>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  );
                })}
              </div>

              <div hidden={!this.state.dataReady} className="">
                <Row>
                  <Col
                    md={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <span
                      hidden={!this.state.dataReady}
                      style={{ marginRight: "1rem" }}
                    >
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <Button
                              data-tip="Print/Download"
                              color="success"
                              type="button"
                            >
                              Print tables
                            </Button>
                          );
                        }}
                        content={() => this.tableRef}
                      />&nbsp;
                      <Switch
                        height={22}
                        width={40}
                        onColor={"#3498db"}
                        offColor={"#2980b9"}
                        onChange={() => this.toggleProp("showLess")}
                        checked={!this.state.showLess}
                      />
                      <span
                        style={{
                          verticalAlign: "super",
                        }}
                      >
                        {" "}
                        Show entire table
                      </span>
                    </span>

                    <span hidden={!this.state.dataReady}>
                      <Switch
                        height={22}
                        width={40}
                        onColor={"#f1c40f"}
                        offColor={"#f39c12"}
                        onChange={() => this.toggleProp("viewDecimals")}
                        checked={this.state.viewDecimals}
                      />
                      <span
                        style={{
                          verticalAlign: "super",
                        }}
                      >
                        {" "}
                        View Decimals
                      </span>
                    </span>

                    <span hidden={true || !this.state.dataReady}>
                      <Switch
                        height={22}
                        width={30}
                        offColor={"#FF0000"}
                        onChange={() => this.toggleProp("showNominal")}
                        checked={this.state.showNominal}
                      />
                      <span
                        style={{
                          verticalAlign: "super",
                        }}
                      >
                        {" "}
                        Show nominal value in graphic
                      </span>
                    </span>
                  </Col>
                </Row>
                <br />
              </div>
              <Row>
                {["", "Growth"].map((type) => {
                  return (
                    <Col md={{ size: 6 }}>
                      <Col
                        hidden={!this.state.dataReady}
                        id="resultsGraphic"
                        md={12}
                        style={{
                          marginLeft: "0.5rem",
                          marginRight: "0.5rem",
                          marginTop: "1rem",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                        }}
                      >
                        <ResponsiveContainer
                          width="100%"
                          height={300}
                          style={{ overflow: "overlay" }}
                        >
                          <AreaChart
                            data={this.state["combinedData" + type]}
                            style={{ fontSize: "0.8rem" }}
                          >
                            <XAxis
                              dataKey="per"
                              label={{
                                value: "Year",
                                dy: 15,
                              }}
                            />
                            <YAxis
                              tickFormatter={(tick) => {
                                return (
                                  "$" + new Intl.NumberFormat("en").format(tick)
                                );
                              }}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                              formatter={(value) =>
                                "$" + new Intl.NumberFormat("en").format(value)
                              }
                            />
                            {this.state.showNominal ? (
                              <Area
                                type="monotone"
                                dataKey="nominal"
                                stroke="#34495e"
                                fill="#f1c40f"
                                unit="$"
                              />
                            ) : (
                              <></>
                            )}
                            {this.state.showNominal ? (
                              <Area
                                type="monotone"
                                dataKey="nominalPerpetual"
                                stroke="#ecf0f1"
                                fill="#f1c40f"
                                unit="$"
                              />
                            ) : (
                              <></>
                            )}
                            <Area
                              type="monotone"
                              label="Siz"
                              dataKey="Fund_Size"
                              stroke="#34495e"
                              fill="#e67e22"
                            />
                            <Area
                              type="monotone"
                              dataKey="Fund_Size_Perpetual"
                              stroke="#34495e"
                              fill="#3498db"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </Col>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
          <ReactTooltip className="tooltip" multiline={true} />
        </Form>
        <div className="bottomRight">
          <ToggleSwitch title={this.state.reducedLineHeight ? "Reduced line height" : "Reduce line height"} value={this.state.reducedLineHeight} setter={this.setReducedLineHeight}></ToggleSwitch>
        </div>
      </div>
    );
  }
}

export default RetirementSimulator;
