import React from "react";
import Switch from "react-switch";
function ToggleSwitch(props) {
    return <>
        <div>
            <Switch
                height={15}
                width={30}
                onColor={"#81ecec"}
                offColor={"#81ecec"}
                onChange={() => props.setter(!props.value)}
                checked={props.value}
            />
            <span>
                &nbsp;{props.title}&nbsp;
                {
                    props.tip && <i
                        data-tip={props.tip}
                        className="fa-info-circle-alt"
                    >i</i>
                }
            </span>
        </div>
    </>;
}
export default ToggleSwitch;
